import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function FAQ({ smallText, headLine, fullText }) {
  const [show, setShow] = useState(false);

  const _Child = () => {
    return (
      <span onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
        {smallText}
      </span>
    );
  };

  return (
    <>
      <_Child />
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{headLine}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">{fullText}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}
