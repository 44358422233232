import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import LogUser from "../../hooks/log";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }

  if (num === 0) return <span>&nbsp;</span>;
  return num.toLocaleString();
};
const convertDateFormat = (dateStr) => {
  // Split the date and time parts
  const [datePart, timePart] = dateStr.split(" ");

  // Split the date part into year, month, and day
  const [year, month, day] = datePart.split("-");

  // Create the new formatted date
  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

export default function ProjectHistory({ project, children }) {
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pname, setPname] = useState("");

  const [data, setData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);

  // Table
  const [bigTable, setBigTable] = useState(false);

  // Details
  const [showDetails, setShowDetails] = useState(false);

  // Gjør tabellen til 1 type, med maks og min år
  const [firstYear, setFirstYear] = useState(null);
  const [lastYear, setLastYear] = useState(null);

  // Finn siste år med data
  const [lastYearData, setLastYearData] = useState(9999);

  useEffect(() => {
    if (show) {
      getData(project);
      getDetailedData(project);
    }

    return () => {
      setLoaded(false);
      setPname("");
      setData([]);
    };
  }, [show]);

  const getData = async (pnr) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/ProjectHistory.php?pnr=" + pnr,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
      setPname(data[0]["data"][0].pname);

      // Hent alle årstall som tall
      const years = data.map((item) => parseInt(item.year, 10));

      // Finn minimum og maksimum årstall
      const minYear = Math.min(...years);
      const maxYear = Math.max(...years);
      setFirstYear(minYear);
      setLastYear(maxYear);

      // Hent alle årstall
      const allYears = data.map((d) => parseInt(d.year, 10));

      // Variabel for å spore siste år der det har vært penger
      let lastYearWithMoney = null;

      // Sjekk alle datasettene
      data.forEach((yearData) => {
        const baseYear = parseInt(yearData.year, 10); // Hvilket år datasettene tilhører

        yearData.data.forEach((entry) => {
          // Finn alle år der det noen gang har vært penger
          Object.entries(entry)
            .filter(
              ([key, value]) => key.startsWith("e") && parseInt(value) > 0
            )
            .forEach(([key, value]) => {
              const calculatedYear = baseYear + parseInt(key.substring(1)) - 1; // Beregn riktig årstall

              // Oppdater siste år der penger var satt av
              if (!lastYearWithMoney || calculatedYear > lastYearWithMoney) {
                lastYearWithMoney = calculatedYear;
              }
            });
        });
      });

      setLastYearData(lastYearWithMoney);

      setLoaded(true);

      LogUser(
        "Historikk",
        "Brukeren ser på historikken til prosjekt " +
          pnr +
          " - " +
          data[0]["data"][0].pname
      );
    } catch (err) {
      return;
    }
  };

  const getDetailedData = async (pnr) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/ProjectHistory.php?pnr=" +
          pnr +
          "&details",
        { credentials: "include" }
      );
      const data = await res.json();
      setDetailedData(data);
      setLoaded(true);
    } catch (err) {
      return;
    }
  };

  const _Child = () => {
    return <div onClick={() => setShow(true)}>{children}</div>;
  };

  return (
    <>
      <_Child />
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        className="history_p_modal"
      >
        {loaded ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title className="w-100">
                <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                  <div className="flex-fill">
                    Historikk {project} - {pname}
                  </div>
                  <div>
                    <div className="me-2">
                      <Button
                        size="sm"
                        onClick={() => setShowDetails((prev) => !prev)}
                      >
                        {showDetails ? "Skjul detaljer" : "Vis detaljer"}
                      </Button>
                    </div>
                    <div>
                      <Button
                        size="sm"
                        onClick={() => setBigTable((prev) => !prev)}
                      >
                        {bigTable ? "Liten tabell" : "Full tabell"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="p-0 m-0"
              style={{
                width: "100vw !important",
              }}
            >
              <div className="container-fluid m-0 p-0">
                <div
                  style={{
                    height: 20,
                    width: "100%",
                    backgroundColor: "white",
                    margin: 0,
                  }}
                />
                <div
                  className="historyTable m-0 px-2 table-responsive"
                  style={{
                    maxHeight: "80dvh",
                    paddingBottom: 20,
                  }}
                >
                  <Table striped className="m-0" bordered>
                    <thead
                      style={{
                        position: "sticky",
                        top: -2,
                        zIndex: 2,
                        boxShadow: "0px -1px 0pc #ccc inset",
                      }}
                    >
                      <tr>
                        <th>Versjon</th>
                        {showDetails && (
                          <>
                            <th>Konto</th>
                            <th>Tjeneste</th>
                          </>
                        )}
                        {[...Array(Math.max(lastYear + 10 - firstYear, 0))].map(
                          (_, i) => {
                            if (parseInt(firstYear) + i > lastYear + 3) {
                              return (
                                <th
                                  key={i}
                                  style={{
                                    display: bigTable ? "table-cell" : "none",
                                  }}
                                >
                                  {firstYear + i}
                                </th>
                              );
                            } else {
                              return <th key={i}>{firstYear + i}</th>;
                            }
                          }
                        )}
                        <th>&sum;</th>

                        <th style={{ width: "20%" }}>
                          <i className="bi bi-chat-left-dots" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailedData.map((d) => {
                        // Check if blank line in front
                        const blankLinesBefore = Math.max(
                          d.year - firstYear,
                          0
                        );
                        const blankLinesAfter = Math.max(lastYear - d.year, 0);
                        const lYearShownDefault = lastYear + 3;

                        // Finne totalen

                        return d.data.map((ec, ecKey) => {
                          return (
                            <tr key={ecKey}>
                              <td
                                style={{
                                  width: "20%",
                                  backgroundColor: parseInt(ec.finallyBudget)
                                    ? "lightblue"
                                    : null,
                                }}
                              >
                                {ec.year} // {ec.version}. {ec.versionComment}{" "}
                                <br />
                                <span className="text-muted">{ec.user}</span>
                                <br />
                                <span className="text-muted">
                                  {convertDateFormat(ec.updated)}
                                </span>
                              </td>

                              {/* Konto og Tjenste */}
                              {showDetails && (
                                <>
                                  <td
                                    className="m-0 p-0"
                                    style={{
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="m-0 p-0"
                                      >
                                        <td>{dc.konto}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="m-0 p-0"
                                    style={{
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="m-0 p-0"
                                      >
                                        <td>{dc.tjeneste}</td>
                                      </tr>
                                    ))}
                                  </td>
                                </>
                              )}

                              {/* Budsjett og beløp */}

                              {showDetails ? (
                                <>
                                  {[...Array(blankLinesBefore)].map((_, i) => {
                                    const filteredData = detailedData
                                      .filter(
                                        (e) =>
                                          parseInt(e.year) === ec.year - i - 1
                                      )
                                      .flatMap((e) => e.data);

                                    const maxVersionData = filteredData.reduce(
                                      (max, obj) =>
                                        obj.version >
                                        (max?.version ?? -Infinity)
                                          ? obj
                                          : max,
                                      null
                                    );

                                    return (
                                      <td
                                        key={i}
                                        className="p-0 m-0 text-muted"
                                        style={{
                                          backgroundColor: parseInt(
                                            ec.finallyBudget
                                          )
                                            ? "lightblue"
                                            : null,
                                        }}
                                      >
                                        {maxVersionData?.details?.map(
                                          (dc, dcKey) => (
                                            <tr
                                              key={`${i}-${dcKey}`}
                                              className="m-0 p-0"
                                            >
                                              <td>{convertNumber(dc.e1)}</td>
                                            </tr>
                                          )
                                        ) || (
                                          <tr key={i} className="m-0 p-0">
                                            <td>
                                              {convertNumber(
                                                maxVersionData?.e1
                                              ) || ""}
                                            </td>
                                          </tr>
                                        )}
                                      </td>
                                    );
                                  })}

                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        ec.year > lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="m-0 p-0"
                                      >
                                        <td>{convertNumber(dc.e1)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 1 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e2)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 2 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e3)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 3 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e4)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 4 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e5)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 5 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e6)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 6 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e7)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 7 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e8)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 8 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e9)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  <td
                                    className="p-0 m-0"
                                    style={{
                                      display:
                                        parseInt(ec.year) + 9 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) => (
                                      <tr
                                        key={`${ecKey}-${dcKey}`}
                                        className="p-0 m-0"
                                      >
                                        <td>{convertNumber(dc.e10)}</td>
                                      </tr>
                                    ))}
                                  </td>
                                  {[...Array(blankLinesAfter)].map((_, i) => (
                                    <td
                                      className="p-0 m-0"
                                      style={{
                                        display: bigTable
                                          ? "table-cell"
                                          : "none",
                                        backgroundColor: parseInt(
                                          ec.finallyBudget
                                        )
                                          ? "lightblue"
                                          : null,
                                      }}
                                      key={i}
                                    >
                                      {ec?.details?.map((dc, dcKey) => (
                                        <tr
                                          key={`${ecKey}-${dcKey}`}
                                          className="m-0 p-0"
                                        >
                                          <td>{convertNumber(0)}</td>
                                        </tr>
                                      ))}
                                    </td>
                                  ))}

                                  <td
                                    className="p-0 m-0 fw-bolder"
                                    style={{
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {ec?.details?.map((dc, dcKey) =>
                                      [
                                        ...Array(Math.max(blankLinesBefore, 1)),
                                      ].map((_, i) => {
                                        const year = ec.year - i - 1;

                                        // Finn det nyeste entry for året
                                        const latestEntry = detailedData
                                          .filter(
                                            (e) => parseInt(e.year) === year
                                          )
                                          .flatMap((e) => e.data)
                                          .reduce(
                                            (max, obj) =>
                                              obj.version >
                                              (max?.version ?? -Infinity)
                                                ? obj
                                                : max,
                                            null
                                          );

                                        // Summer alle relevante verdier
                                        const totalAmount =
                                          (parseInt(
                                            latestEntry?.details?.[dcKey].e1
                                          ) || 0) +
                                          [
                                            dc.e1,
                                            dc.e2,
                                            dc.e3,
                                            dc.e4,
                                            dc.e5,
                                            dc.e6,
                                            dc.e7,
                                            dc.e8,
                                            dc.e9,
                                            dc.e10,
                                          ]
                                            .map((num) => parseInt(num) || 0)
                                            .reduce((sum, val) => sum + val, 0);

                                        return (
                                          <tr key={dcKey} className="m-0 p-0">
                                            <td>
                                              {convertNumber(totalAmount)}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    )}
                                  </td>
                                </>
                              ) : (
                                <>
                                  {[...Array(blankLinesBefore)].map((_, i) => (
                                    <td key={i} className="text-muted">
                                      {convertNumber(
                                        (
                                          detailedData
                                            .filter(
                                              (e) =>
                                                parseInt(e.year) ===
                                                ec.year - i - 1
                                            )
                                            .flatMap((e) => e.data)
                                            .reduce(
                                              (max, obj) =>
                                                obj.version >
                                                (max?.version ?? -Infinity)
                                                  ? obj
                                                  : max,
                                              null
                                            ) || {}
                                        ).e1
                                      ) || ""}
                                    </td>
                                  ))}
                                  <td
                                    style={{
                                      display:
                                        ec.year > lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e1)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 1 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e2)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 2 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e3)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 3 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e4)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 4 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e5)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 5 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e6)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 6 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e7)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 7 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e8)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 8 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e9)}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        parseInt(ec.year) + 9 >
                                        lYearShownDefault
                                          ? bigTable
                                            ? "table-cell"
                                            : "none"
                                          : "table-cell",
                                      backgroundColor: parseInt(
                                        ec.finallyBudget
                                      )
                                        ? "lightblue"
                                        : null,
                                    }}
                                  >
                                    {convertNumber(ec.e10)}
                                  </td>
                                  {[...Array(blankLinesAfter)].map((_, i) => (
                                    <td
                                      style={{
                                        display: bigTable
                                          ? "table-cell"
                                          : "none",
                                        backgroundColor: parseInt(
                                          ec.finallyBudget
                                        )
                                          ? "lightblue"
                                          : null,
                                      }}
                                      key={i}
                                    >
                                      <td>{convertNumber(0)}</td>
                                    </td>
                                  ))}

                                  {[
                                    ...Array(Math.max(blankLinesBefore, 1)),
                                  ].map((_, i) => {
                                    const year = ec.year - i - 1;

                                    // Finn det nyeste entry for året
                                    const latestEntry = detailedData
                                      .filter((e) => parseInt(e.year) === year)
                                      .flatMap((e) => e.data)
                                      .reduce(
                                        (max, obj) =>
                                          obj.version >
                                          (max?.version ?? -Infinity)
                                            ? obj
                                            : max,
                                        null
                                      );

                                    // Summer alle relevante verdier
                                    const totalAmount =
                                      (parseInt(latestEntry?.e1) || 0) +
                                      [
                                        ec.e1,
                                        ec.e2,
                                        ec.e3,
                                        ec.e4,
                                        ec.e5,
                                        ec.e6,
                                        ec.e7,
                                        ec.e8,
                                        ec.e9,
                                        ec.e10,
                                      ]
                                        .map((num) => parseInt(num) || 0)
                                        .reduce((sum, val) => sum + val, 0);

                                    return (
                                      <td
                                        key={i}
                                        className="fw-bolder"
                                        style={{
                                          backgroundColor: parseInt(
                                            ec.finallyBudget
                                          )
                                            ? "lightblue"
                                            : null,
                                        }}
                                      >
                                        {convertNumber(totalAmount)}
                                      </td>
                                    );
                                  })}
                                </>
                              )}

                              <td
                                style={{
                                  backgroundColor: parseInt(ec.finallyBudget)
                                    ? "lightblue"
                                    : null,
                                }}
                              >
                                <ShortComment comment={ec.comment} />
                              </td>
                            </tr>
                          );
                        });
                      })}
                    </tbody>
                  </Table>
                </div>

                {detailedData.map((d, dKey) => {
                  return (
                    <div className="historyRow d-none" key={dKey}>
                      <div className="historyInfo">
                        <div className="d-flex justify-content-between p-2">
                          <h4>Regnskapsår {d.year}</h4>
                        </div>
                      </div>
                      <div className="historyTable m-0 p-2 table-responsive">
                        <Table striped hover className="m-0">
                          <thead
                            style={{ position: "sticky", top: -15, zIndex: 2 }}
                          >
                            <tr>
                              <th>Versjon</th>
                              {showDetails && (
                                <>
                                  <th>Konto</th>
                                  <th>Tjeneste</th>
                                </>
                              )}
                              <th>{d.year}</th>
                              <th>{parseInt(d.year) + 1}</th>
                              <th>{parseInt(d.year) + 2}</th>
                              <th>{parseInt(d.year) + 3}</th>
                              {bigTable && (
                                <>
                                  <th>{parseInt(d.year) + 4}</th>
                                  <th>{parseInt(d.year) + 5}</th>
                                  <th>{parseInt(d.year) + 6}</th>
                                  <th>{parseInt(d.year) + 7}</th>
                                  <th>{parseInt(d.year) + 8}</th>
                                  <th>{parseInt(d.year) + 9}</th>
                                </>
                              )}
                              {showDetails && (
                                <>
                                  <th>Notat</th>
                                </>
                              )}
                              <th style={{ width: "20%" }}>
                                <i className="bi bi-chat-left-dots" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {d.data?.map((ec, ecKey) => {
                              return (
                                <tr key={ecKey}>
                                  <td>
                                    {ec.version}. {ec.versionComment} <br />
                                    <span className="text-muted">
                                      {ec.user}
                                    </span>
                                    <br />
                                    <span className="text-muted">
                                      {convertDateFormat(ec.updated)}
                                    </span>
                                  </td>
                                  {showDetails ? (
                                    <>
                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="m-0 p-0"
                                          >
                                            <td>{dc.konto}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="m-0 p-0"
                                          >
                                            <td>{dc.tjeneste}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="m-0 p-0"
                                          >
                                            <td>{convertNumber(dc.e1)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="p-0 m-0"
                                          >
                                            <td>{convertNumber(dc.e2)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="p-0 m-0"
                                          >
                                            <td>{convertNumber(dc.e3)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="p-0 m-0"
                                          >
                                            <td>{convertNumber(dc.e4)}</td>
                                          </tr>
                                        ))}
                                      </td>
                                      {bigTable && (
                                        <>
                                          <td className="p-0 m-0">
                                            {ec?.details?.map((dc, dcKey) => (
                                              <tr
                                                key={`${ecKey}-${dcKey}`}
                                                className="p-0 m-0"
                                              >
                                                <td>{convertNumber(dc.e5)}</td>
                                              </tr>
                                            ))}
                                          </td>

                                          <td className="p-0 m-0">
                                            {ec?.details?.map((dc, dcKey) => (
                                              <tr
                                                key={`${ecKey}-${dcKey}`}
                                                className="p-0 m-0"
                                              >
                                                <td>{convertNumber(dc.e6)}</td>
                                              </tr>
                                            ))}
                                          </td>

                                          <td className="p-0 m-0">
                                            {ec?.details?.map((dc, dcKey) => (
                                              <tr
                                                key={`${ecKey}-${dcKey}`}
                                                className="p-0 m-0"
                                              >
                                                <td>{convertNumber(dc.e7)}</td>
                                              </tr>
                                            ))}
                                          </td>

                                          <td className="p-0 m-0">
                                            {ec?.details?.map((dc, dcKey) => (
                                              <tr
                                                key={`${ecKey}-${dcKey}`}
                                                className="p-0 m-0"
                                              >
                                                <td>{convertNumber(dc.e8)}</td>
                                              </tr>
                                            ))}
                                          </td>

                                          <td className="p-0 m-0">
                                            {ec?.details?.map((dc, dcKey) => (
                                              <tr
                                                key={`${ecKey}-${dcKey}`}
                                                className="p-0 m-0"
                                              >
                                                <td>{convertNumber(dc.e9)}</td>
                                              </tr>
                                            ))}
                                          </td>

                                          <td className="p-0 m-0">
                                            {ec?.details?.map((dc, dcKey) => (
                                              <tr
                                                key={`${ecKey}-${dcKey}`}
                                                className="p-0 m-0"
                                              >
                                                <td>{convertNumber(dc.e10)}</td>
                                              </tr>
                                            ))}
                                          </td>
                                        </>
                                      )}

                                      <td className="p-0 m-0">
                                        {ec?.details?.map((dc, dcKey) => (
                                          <tr
                                            key={`${ecKey}-${dcKey}`}
                                            className="p-0 m-0"
                                          >
                                            <td>
                                              {dc.lineComment
                                                ? dc.lineComment
                                                : `${"\xa0"}`}
                                            </td>
                                          </tr>
                                        ))}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>{convertNumber(ec.e1)}</td>
                                      <td>{convertNumber(ec.e2)}</td>
                                      <td>{convertNumber(ec.e3)}</td>
                                      <td>{convertNumber(ec.e4)}</td>
                                      {bigTable && (
                                        <>
                                          <td>{convertNumber(ec.e5)}</td>
                                          <td>{convertNumber(ec.e6)}</td>
                                          <td>{convertNumber(ec.e7)}</td>
                                          <td>{convertNumber(ec.e8)}</td>
                                          <td>{convertNumber(ec.e9)}</td>
                                          <td>{convertNumber(ec.e10)}</td>
                                        </>
                                      )}
                                    </>
                                  )}
                                  <td>
                                    <ShortComment comment={ec.comment} />
                                  </td>
                                  <td className="d-none">
                                    {ec.updated && (
                                      <ButtonToolbar>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              {ec.user && (
                                                <>
                                                  {ec.user} <br />{" "}
                                                </>
                                              )}
                                              {ec.updated}
                                            </Tooltip>
                                          }
                                        >
                                          <i
                                            className="bi bi-clock"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </OverlayTrigger>
                                      </ButtonToolbar>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Modal.Body>
          </>
        ) : (
          <div className="container my-4">
            <center>
              <Spinner />
            </center>
          </div>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const ShortComment = ({ comment }) => {
  const [show, setShow] = useState(false);

  return comment?.length > 115 && !show ? (
    <>
      <span>{comment.slice(0, 115)}...</span>
      <br />
      <span
        style={{ cursor: "pointer", float: "right" }}
        onClick={() => setShow(true)}
        className="text-muted"
      >
        Les mer...
      </span>
    </>
  ) : (
    <>
      <span>
        {(comment || "").split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </span>

      {show && (
        <>
          <br />
          <span
            style={{ cursor: "pointer", float: "right" }}
            onClick={() => setShow(false)}
            className="text-muted"
          >
            Skjul...
          </span>
        </>
      )}
    </>
  );
};
