import React from "react";

export default function UserNotFound() {
  return (
    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-dark text-white">
              <div className="card-body p-5 text-center bg-dark">
                <img src="logo192.png" />
                <h2>TKVA Prosjekt</h2>
                <hr />
                <div
                  className="alert alert-danger d-flex align-items-center justify-content-center"
                  role="alert"
                >
                  <div>
                    <b>Error: Brukeren finnes ikke i databasen</b> <br />
                    <span className="text-muted">
                      Be om å få opprettet bruker for å logge inn
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
