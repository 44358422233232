import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

export default function AuthLogin() {
  const params = useParams();
  const navigate = useNavigate();

  const { refreshAuth } = useAuth();

  const [loaded, setLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  /*
  useEffect(() => {
    login();
  }, [params]);
  */

  useEffect(() => {
    loginCookie();
  }, []);

  const loginCookie = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/auth.php", {
        method: "GET",
        credentials: "include",
      });

      if (res.ok) {
        refreshAuth();
        navigate("../", { replace: true });
      } else {
        setLoaded(true);
        setErrMsg("Noe gikk galt...");
      }
    } catch (err) {
      setLoaded(true);
      setErrMsg(err.message);
    }
  };

  const login = async () => {
    try {
      const postData = {
        token: params.token,
        action: "login",
      };

      const res = await fetch("https://prosjekt.tkva.no/api/auth.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });

      if (res.ok) {
        const data = await res.json();
        refreshAuth();
        navigate("../", { replace: true });
      } else {
        setLoaded(true);
        setErrMsg("Noe gikk galt...");
      }
    } catch (err) {
      setLoaded(true);
      setErrMsg(err.message);
    }
  };

  if (loaded)
    return (
      <div className="container">
        <h2>Error</h2>
        <p>{errMsg}</p>
      </div>
    );
}
