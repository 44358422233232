import React from "react";
import { ModalWrapper } from "./ModalWrapper";

export default function Personvern() {
  return (
    <ModalWrapper
      triggerItem={
        <div
          className="flex items-center gap-2 cursor-pointer hover:text-blue-600"
          style={{ cursor: "pointer" }}
        >
          <img
            src="/personvern.png"
            alt="Personvern"
            className="h-9 w-9"
            width={35}
            height={35}
          />
          <span className="text-gray-600 font-medium">Personvern</span>
        </div>
      }
      title="Personvernerklæring"
    >
      <div className="space-y-4 text-gray-800">
        <p>
          Vi i <strong>TKVA</strong> (Tromsø kommune - Vann og avløp) forstår
          viktigheten av personvern og er dedikert til å beskytte dine
          personopplysninger.
        </p>
        <p>
          Denne personvernerklæringen forklarer hvordan vi samler inn, bruker,
          deler og beskytter dine personopplysninger når du besøker vår interne
          prosjekt-nettside.
        </p>
        <p className="font-semibold">Sist oppdatert: 02.04.2025</p>
        <hr className="border-gray-300" />
        <ol className="list-decimal pl-6 space-y-3">
          <li>
            <strong>Behandlingsgrunnlag</strong>
            <p>
              For alminnelig samhandling i prosjekter vil det rettslige
              grunnlaget være pvf. art 6 nr. 1 bokstav f) "berettiget
              interesse".
            </p>
          </li>
          <li>
            <strong>Hvilke opplysninger samler vi inn?</strong>
            <ul className="list-disc pl-6">
              <li>Navn og e-postadresse.</li>
              <li>Lese- og handlingshistorikk fra nettsiden.</li>
              <li>
                Kommentarer, økonomi og fremdrift i prosjektene som er lagt inn
                (rapportert) av prosjektledere og/eller prosjekteiere.
              </li>
              <li>Prosjektlederens rolle til spesifikke prosjekter.</li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Hvordan bruker vi opplysningene?</strong>
            <ul className="list-disc pl-6">
              <li>Tildeling av prosjekter til prosjektledere.</li>
              <li>
                Gi nødvendige rettigheter på nettsiden for lesing eller
                redigering.
              </li>
              <li>
                Visning av prosjektlederens navn og kommentarer for transparens.
              </li>
              <li>
                Oversikt over prosjektenes fremdriftsplan, økonomi og status
                (kommentarer og fase)
              </li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Lagringstid for personopplysninger</strong>
            <ul>
              <li>
                Personopplysninger: Vi lagrer personopplysninger inntil du ber
                om sletting.
              </li>
              <li>
                Prosjektopplysninger: Kommentarer, tall (budsjett) og fremdrift
                (status i prosjektet) som er rapportert lagres permanent for å
                dokumentere endringer i budsjett og prosjektutvikling.
              </li>
              <li>Handlingshistorikk: Lagres i 3 måneder (90 dager)</li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Databehandlere</strong>
            <p>Vi deler ikke dine personopplysninger med tredjeparter.</p>
            <p>
              PRO ISP: Nettsiden driftes av en trejdepart (RPO ISP), og dataen
              lagres sikkert hos tjenesteleverandør. Se{" "}
              <a
                href="https://www.proisp.no/om-oss/databehandleravtale/"
                className="text-blue-600 underline"
                target="_blank"
              >
                databehandleravtale
              </a>
            </p>
          </li>
          <li>
            <strong>Sikkerhetstiltak</strong>
            <p>
              Vi beskytter dine data gjennom HTTPS-kryptering og lagring i en
              sikker SQL-database.
            </p>
          </li>
          <li>
            <strong>Dine rettigheter</strong>
            <p className="text-gray-500 text-sm">
              Ta kontakt på vannpost@tromso.kommune.no for å utøve dine
              rettigheter.
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>Rett til innsyn:</strong> Du kan be om en kopi av
                opplysninger vi har om deg.
              </li>
              <li>
                <strong>Rett til korrigering:</strong> Du kan be oss rette eller
                supplere feilaktige opplysninger.
              </li>
              <li>
                <strong>Rett til sletting:</strong> Du kan be om sletting av
                dine personopplysninger.
              </li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Hvordan trekke tilbake samtykke?</strong>
            <p>
              For å trekke tilbake samtykke, ta kontakt på e-post og be om
              sletting av data.
            </p>
          </li>
          <li>
            <strong>Informasjonskapsler (cookies)</strong>
            <p>
              Informasjonskapsler (cookies) er små tekstfiler som plasseres på
              din datamaskin når du laster ned en nettside. Informasjonskapsler
              er svært vanlig, og brukes for eksempel slik at nettsiden skal
              fungere optimalt, eller for å registrere hvordan en nettside blir
              brukt. Informasjonskapsler kan dermed være hensiktsmessig både for
              brukeren og nettstedseier. For å verne brukerens personvern og
              kommunikasjonsutstyr er det gitt krav om informasjon og samtykke
              når en virksomhet ønsker å benytte informasjonskapsler.
              <br />
              <br />
              Nedenfor ser du hvilke informasjonskapsler vi bruker. <br />
              <b>
                Vi bruker kun nødvendige informasjonskapsler og lagrer ingen
                analyse- eller markedsføringscookies.
              </b>
            </p>
            <ul className="list-disc pl-6">
              <li>
                <strong>PHPSESSID</strong>: Nødvendig for å opprettholde
                brukerøkten. Oppbevaringstid: til nettleseren lukkes.
              </li>
              <li>
                <strong>authTokenMSAL</strong>: Nødvendig streng for å logge
                inn. Oppbevaringstid: Slettes etter innlogging.
              </li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Klagerett</strong>
            <p>
              Hvis du mener vi behandler dine personopplysninger i strid med
              regelverket, kan du klage til{" "}
              <a
                href="https://www.datatilsynet.no"
                className="text-blue-600 underline"
                target="_blank"
              >
                Datatilsynet
              </a>
              .
            </p>
          </li>
          <li>
            <strong>Endringer i personvernerklæringen</strong>
            <p>
              Vi kan oppdatere denne personvernerklæringen ved behov. Ved
              vesentlige endringer vil vi varsle brukerne på nettsiden. Den
              nyeste versjonen vil alltid være tilgjengelig her.
            </p>
          </li>
          <li>
            <strong>Kontakt oss</strong>
            <p>
              Har du spørsmål om vår personvernerklæring? Kontakt oss på{" "}
              <a
                href="mailto:vannpost@tromso.kommune.no"
                className="text-blue-600 underline"
              >
                vannpost@tromso.kommune.no
              </a>
              .
            </p>
          </li>
        </ol>
      </div>
    </ModalWrapper>
  );
}
