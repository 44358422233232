import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useAuth } from "../../hooks/auth";
import Personvern from "../../components/Personvern";

export default function Template({ children, page, headline }) {
  //const [auth, setAuth] = useState(false);
  //const [isAdmin, setAdmin] = useState(false);
  const { permissions, auth, developement } = useAuth();

  const { logout } = useAuth();

  const [activeNav, setActiveNav] = useState("overview");

  // Varsler
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // Forms
  const [questions, setQuestions] = useState([]);
  const [textAnswer, setTextAnswer] = useState("");

  // Auth
  useEffect(() => {
    /*
    const checkAuth = async () => {
      try {
        const res = await fetch("https://prosjekt.tkva.no/api/auth.php", {
          credentials: "include",
        });
        if (res.ok) {
          const result = await res.json();
          if (result.auth) {
            setAuth(true);
            if (result.admin) {
              setAdmin(true);
            }
            if (parseInt(result.dev)) {
              setDevelopement(true);
            } else {
              setDevelopement(false);
            }
          } else {
            navigate("../login");
          }
        }
      } catch (err) {
        console.log(err.message);
        navigate("../login");
      }
    };
    */

    const checkNotifications = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/notifications.php",
          {
            credentials: "include",
          }
        );
        const data = await res.json();
        setNotifications(data);
      } catch (err) {
        return;
      }
    };

    const checkForms = async () => {
      try {
        const res = await fetch("https://prosjekt.tkva.no/api/forms.php", {
          credentials: "include",
        });
        const data = await res.json();
        setQuestions(data);
      } catch (err) {
        return;
      }
    };

    //checkAuth();
    checkNotifications();
    checkForms();

    const checkInt = setInterval(() => {
      //checkAuth();
      checkNotifications();
    }, 60000);

    return () => {
      clearInterval(checkInt);
    };
  }, []);

  // Page
  useEffect(() => {
    setActiveNav(page);
    setShowNotifications(false);
  }, [page]);

  const getNotificationImage = (type) => {
    switch (type) {
      case "project":
        return "https://cdn-icons-png.flaticon.com/128/9085/9085966.png";
        break;
      default:
        return "https://cdn-icons-png.flaticon.com/128/11947/11947041.png";
    }
  };

  const readNotification = async (id) => {
    const postData = {
      action: "MarkAsRead",
      id: id,
    };
    try {
      await fetch("https://prosjekt.tkva.no/api/notifications.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
    } catch (err) {
      return;
    }

    setNotifications((prev) => {
      const index = prev.findIndex((p) => p.id === id);
      if (index !== -1) {
        // Lag en ny kopi av notifikasjonene
        const newData = [...prev];
        // Fjern elementet fra kopien
        newData.splice(index, 1);
        return newData;
      } else {
        return prev;
      }
    });
  };

  const readAllNotifications = async () => {
    const postData = {
      action: "MarkAllAsRead",
    };
    try {
      await fetch("https://prosjekt.tkva.no/api/notifications.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
    } catch (err) {
      return;
    }
    setNotifications([]);
  };

  const answerQuestion = async (id, answer) => {
    const postData = {
      questionId: id,
      answer: answer,
    };

    try {
      await fetch("https://prosjekt.tkva.no/api/forms.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
    } catch (err) {
      return;
    }

    setQuestions((prev) => {
      const index = 0;
      if (index !== -1) {
        // Lag en ny kopi av notifikasjonene
        const newData = [...prev];
        // Fjern elementet fra kopien
        newData.splice(index, 1);
        return newData;
      } else {
        return prev;
      }
    });

    setTextAnswer("");
  };

  return !auth ? (
    <div className="container-fluid h-100 w-100">
      <div className="position-absolute" style={{ top: "50%", left: "50%" }}>
        <Spinner />
      </div>
      <div className="position-absolute" style={{ top: "55%", left: "48%" }}>
        <span>Laster inn...</span>
      </div>
    </div>
  ) : (
    <>
      <div
        className="container-fluid m-0 p-0"
        style={{
          position: "relative",
        }}
      >
        {developement ? (
          <>
            <div
              className="position-absolute"
              style={{ top: "50%", left: "50%" }}
            >
              <Spinner />
            </div>
            <div
              className="position-absolute"
              style={{ top: "55%", left: "48%" }}
            >
              <span>Siden er under utvikling...</span>
            </div>
          </>
        ) : (
          <div
            className="d-flex flex-nowrap position-relative"
            /*style={{ height: "100dvh", width: "100dvw" }}*/
          >
            <div
              id="nav"
              className="p-2 d-flex flex-column align-items-center"
              style={{
                width: "80px",
                height: "100dvh",
                borderTopRightRadius: 15,
                position: "fixed",
                zIndex: 999,
              }}
            >
              <div className="d-flex flex-column flex-fill">
                {/* eslint-disable */}
                <div className="nav-tab">
                  <Link to={"/"}>
                    <img
                      className={activeNav === "overview" && "active"}
                      src="https://cdn-icons-png.flaticon.com/128/2549/2549900.png"
                      style={{ height: 45, width: 45 }}
                    />
                  </Link>
                  <div className="nav-tab-text">
                    <span>Oversikt</span>
                  </div>
                </div>
                <div
                  style={{
                    borderTopColor: "rgba(255,255,255,0.5)",
                    borderTopWidth: 1,
                    marginTop: 15,
                    borderTopStyle: "solid",
                    height: 20,
                    width: "100%",
                  }}
                />
                <div className="nav-tab my-2">
                  <Link to={"/projects"}>
                    <img
                      className={activeNav === "projects" && "active"}
                      src="https://cdn-icons-png.flaticon.com/128/9085/9085966.png"
                      style={{ height: 45, width: 45 }}
                    />
                  </Link>
                  <div className="nav-tab-text">
                    <span>Prosjekter</span>
                  </div>
                </div>
                <div className="nav-tab my-2 d-none">
                  <img
                    className={activeNav === "reports" && "active"}
                    src="https://cdn-icons-png.flaticon.com/512/1786/1786640.png"
                    style={{ height: 45, width: 45 }}
                  />
                  <div className="nav-tab-text">
                    <span>Rapportering</span>
                  </div>
                </div>
                <div className="nav-tab my-2">
                  <Link to={"/history"}>
                    <img
                      className={activeNav === "history" && "active"}
                      src="https://cdn-icons-png.flaticon.com/128/9964/9964353.png"
                      style={{ height: 45, width: 45 }}
                    />
                  </Link>
                  <div className="nav-tab-text">
                    <span>Historikk</span>
                  </div>
                </div>
                <div className="nav-tab my-2">
                  <Link to="/document">
                    <img
                      className={activeNav === "document" && "active"}
                      src="https://cdn-icons-png.flaticon.com/128/1092/1092216.png"
                      style={{ height: 45, width: 45 }}
                    />
                  </Link>
                  <div className="nav-tab-text">
                    <span>Dokument</span>
                  </div>
                </div>
                <div className="nav-tab my-2">
                  <Link to="/powerbi">
                    <img
                      className={activeNav === "powerbi" && "active"}
                      src="https://vennersys.co.uk/wp-content/uploads/2023/03/PowerBI-Icon.png"
                      style={{ height: 45, width: 45 }}
                    />
                  </Link>
                  <div className="nav-tab-text">
                    <span>Power BI</span>
                  </div>
                </div>
              </div>
              {permissions?.length > 0 && (
                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: 25 }}
                >
                  <div className="nav-tab">
                    <Link to={"/admin"}>
                      <img
                        className={activeNav === "admin" && "active"}
                        src="https://cdn-icons-png.flaticon.com/128/10337/10337304.png"
                        style={{ height: 45, width: 45 }}
                      />
                    </Link>
                    <div className="nav-tab-text">
                      <span>Admin</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex flex-column p-2"
              style={{ flex: 1, marginLeft: 80 }}
            >
              {/* Heading */}
              <div
                id="headerTop"
                style={{
                  zIndex: 4,
                  height: "83px",
                  width: "100%",
                  position: "fixed",
                  top: 0,
                }}
              />
              <div
                id="header"
                className="d-flex flex-wrap p-1 justify-content-between d"
                style={{
                  borderTopRightRadius: 5,
                  borderTopLeftRadius: 5,
                  marginBottom: 5,
                  marginLeft: 5,
                  marginRight: 5,
                  height: 75,
                  position: "fixed",
                  width: "calc(100% - 100px)",
                  zIndex: 5,
                }}
              >
                <div
                  className="d-flex flex-row  d-none d-md-flex align-items-center"
                  style={{
                    minWidth: "400px",
                  }}
                >
                  <img
                    className="rounded p-2"
                    style={{ marginRight: 25, width: 100 }}
                    src={require("../../logo-transparent.png")}
                  />
                  <h4
                    className="p-2"
                    style={{
                      marginLeft: 10,
                      color: "#105a9b",
                      letterSpacing: 2,
                    }}
                  >
                    {headline}
                  </h4>
                </div>
                <div className="d-flex flex-row flex-grow-1 justify-content-end align-self-center">
                  <img
                    onClick={() => setShowNotifications((prev) => !prev)}
                    className={
                      !showNotifications &&
                      notifications.length > 0 &&
                      "img-notifications"
                    }
                    style={{
                      height: 35,
                      width: 35,
                      cursor: "pointer",
                      backgroundColor: showNotifications && "black",
                      filter: showNotifications && "invert(9%)",
                      borderRadius: showNotifications && 5,
                    }}
                    src="https://icones.pro/wp-content/uploads/2022/02/icone-de-cloche-et-d-alerte-gris.png"
                  />
                  <Link to="/profile">
                    <img
                      style={{
                        height: 35,
                        width: 35,
                        backgroundColor: page === "profile" && "black",
                        filter: page === "profile" && "invert(9%)",
                        borderRadius: page === "profile" && 5,
                      }}
                      src="https://casaconsult.no/wp-content/uploads/2017/06/profile-icon-9.png"
                    />
                  </Link>
                  <div>
                    <Personvern />
                  </div>

                  <img
                    onClick={() => logout()}
                    style={{
                      cursor: "pointer",
                      height: 35,
                      width: 35,
                    }}
                    className="ms-3"
                    src="https://cdn-icons-png.flaticon.com/128/4400/4400828.png"
                  />
                </div>
                {showNotifications && (
                  <div
                    style={{
                      position: "absolute",
                      top: 80,
                      maxWidth: "90dvw",
                      right: 15,
                      width: "400px",
                      maxHeight: "700px",
                      zIndex: 99,
                      backgroundColor: "rgba(225, 225, 225, 1)",
                      borderRadius: 7.5,
                      padding: 1.5,
                      overflowY: "auto",
                      overflowX: "hidden",
                      color: "black",
                    }}
                  >
                    <div className="row">
                      {notifications?.length <= 0 ? (
                        <p
                          style={{
                            color: "black",
                            marginLeft: 15,
                            marginTop: 15,
                          }}
                        >
                          Ingen nye varsler
                        </p>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "rgba(125, 125, 125, 0.8)",
                            textAlign: "right",
                            paddingRight: 15,
                          }}
                        >
                          <small
                            style={{ color: "white", cursor: "pointer" }}
                            onClick={() => readAllNotifications()}
                          >
                            Merk alle som lest
                          </small>
                        </div>
                      )}

                      {notifications?.map((notification, key) => {
                        return (
                          <div
                            className="col-sm"
                            key={key}
                            style={{ minWidth: "100%" }}
                          >
                            <div className="d-flex flex-column ">
                              <div className="p-2">
                                <Alert variant="success p-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <img
                                      src={getNotificationImage(
                                        notification.type
                                      )}
                                      className="mx-2"
                                      style={{ height: 25, width: 25 }}
                                    />
                                    <span style={{ width: "75%" }}>
                                      {notification.summary}
                                    </span>
                                    <div
                                      className="d-flex flex-column justify-content-center align-items-center p-2"
                                      style={{
                                        borderLeft: "1px #000 dotted",
                                        backgroundColor: "#c8d1c8",
                                      }}
                                    >
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className="fw-light"
                                        onClick={() =>
                                          readNotification(notification.id)
                                        }
                                      >
                                        slett
                                      </span>
                                      <div className="badge  text-primary text-wrap d-flex justify-content-end mt-2">
                                        {notification?.created}
                                      </div>
                                    </div>
                                  </div>
                                </Alert>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {/* Heading */}

              <div
                className="p-2"
                style={{
                  flex: 1,
                  marginTop: 80,
                }}
                id="mainContent"
              >
                {children}
              </div>
            </div>
            {questions?.length > 0 && (
              <div
                className="p-2"
                style={{
                  minWidth: "25%",
                  position: "absolute",
                  bottom: 65,
                  backgroundColor: "lightgrey",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                  borderRadius: 5,
                }}
              >
                {questions?.[0] && (
                  <>
                    <h5>{questions?.[0]?.question}</h5>
                    <hr />
                    {questions?.[0]?.answerMethod === "stars" && (
                      <>
                        <div className="d-flex justify-content-center">
                          <i
                            className="p-2 bi bi-star display-5 formStar"
                            style={{ cursor: "pointer" }}
                            onClick={() => answerQuestion(questions[0].id, 1)}
                          />
                          <i
                            className="p-2 bi bi-star display-5 formStar"
                            style={{ cursor: "pointer" }}
                            onClick={() => answerQuestion(questions[0].id, 2)}
                          />
                          <i
                            className="p-2 bi bi-star display-5 formStar"
                            style={{ cursor: "pointer" }}
                            onClick={() => answerQuestion(questions[0].id, 3)}
                          />
                          <i
                            className="p-2 bi bi-star display-5 formStar"
                            style={{ cursor: "pointer" }}
                            onClick={() => answerQuestion(questions[0].id, 4)}
                          />
                          <i
                            className="p-2 bi bi-star display-5 formStar"
                            style={{ cursor: "pointer" }}
                            onClick={() => answerQuestion(questions[0].id, 5)}
                          />
                        </div>
                      </>
                    )}

                    {questions?.[0]?.answerMethod === "text" && (
                      <>
                        <div className="d-flex flex-column justify-content-center">
                          <textarea
                            defaultValue={textAnswer}
                            className="mb-2"
                            style={{
                              width: "100%",
                              minWidth: "440px",
                              minHeight: "120px",
                            }}
                            onChange={(e) => setTextAnswer(e.target.value)}
                          />
                          <Button
                            onClick={() =>
                              answerQuestion(questions[0].id, textAnswer)
                            }
                          >
                            Send
                          </Button>
                        </div>
                      </>
                    )}

                    {questions?.[0]?.answerMethod === "ok" && (
                      <>
                        <div className="d-flex flex-column justify-content-center">
                          <Button
                            onClick={() =>
                              answerQuestion(questions[0].id, textAnswer)
                            }
                          >
                            OK
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
